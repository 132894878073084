export function getYear(): number {
  let year = localStorage.getItem("year");
  if (!year) {
    year = getCurrentYear().toString();
    localStorage.setItem("year", year);
  }
  return parseInt(year);
}

export function getCurrentYear(): number {
  const d = new Date();
  if (d.getUTCMonth() >= 3) {
    return d.getUTCFullYear();
  }
  return d.getUTCFullYear() - 1;
}
