import { Modal } from "react-bootstrap";
import { useAppSelector } from "../../app/hooks";
import { Book, Category } from "../../app/types";
import { getYear } from "../../functions/date";
import BookForm from "./book-form";

interface BookModalProps {
  show: boolean;
  setShowModal: (value: boolean) => void;
  book?: Book;
  categories: Category[];
}

export default function BookModal(props: BookModalProps) {
  const { show, setShowModal, book, categories } = props;

  const theme = useAppSelector((state) => state.settings.theme);

  const year = getYear();
  const title =
    typeof book === "undefined" ? "Add Book" : `Edit "${book.title}"`;
  return (
    <Modal show={show} size="lg" contentClassName={`shadow ${theme}`}>
      <Modal.Header>
        <Modal.Title>
          {title} - {year}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BookForm
          close={() => setShowModal(false)}
          book={book}
          categories={categories}
        />
      </Modal.Body>
    </Modal>
  );
}
