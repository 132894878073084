import { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { Book, Category } from "../../app/types";
import BookRow from "./book-table";
import BookModal from "./book-modal";
import { XLg } from "react-bootstrap-icons";

interface BookProps {
  books?: Book[];
  categories?: Category[];
}

export function Books(props: BookProps) {
  const { books, categories } = props;

  const [showModal, setShowModal] = useState(false);
  const [editBook, setEditBook] = useState<Book | undefined>(undefined);
  const [filterText, setFilter] = useState("");

  if (typeof books === "undefined" || typeof categories === "undefined") {
    return <div />;
  }

  const rows = books
    .filter(
      (book) =>
        book.author.toLowerCase().includes(filterText) ||
        book.title.toLowerCase().includes(filterText)
    )
    .map((book) => (
      <BookRow
        book={book}
        categories={categories}
        key={book.id}
        setBook={setEditBook}
        showModal={setShowModal}
      />
    ));

  return (
    <Container fluid className="book-container p-0">
      <div
        style={{ display: "flex", gap: 10, marginBottom: 10, paddingRight: 5 }}
      >
        <div style={{ position: "relative", flexGrow: 8 }}>
          <Form.Control
            onChange={(e) => setFilter(e.target.value.toLowerCase())}
            value={filterText}
            placeholder="Filter"
          />
          {filterText && (
            <XLg
              style={{
                position: "absolute",
                right: 12,
                top: 9,
                cursor: "pointer",
              }}
              size={20}
              onClick={() => setFilter("")}
            />
          )}
        </div>
        <Button
          variant="primary"
          onClick={() => {
            setEditBook(undefined);
            setShowModal(true);
          }}
          style={{ minWidth: 100 }}
        >
          Add Book
        </Button>
      </div>
      <div className="book-table" style={{ paddingRight: 5 }}>
        {rows}
        {!rows.length && (
          <div className="mx-auto text-center">
            You have no books loaded. Add a book to begin.
          </div>
        )}
      </div>
      <BookModal
        show={showModal}
        book={editBook}
        setShowModal={setShowModal}
        categories={categories || []}
      />
    </Container>
  );
}
