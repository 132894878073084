import { useEffect, useState } from "react";
import { Star, StarFill, StarHalf } from "react-bootstrap-icons";

interface RatingProps {
  rating?: number;
  editable?: boolean;
  direction?: "horizontal" | "vertical";
  externalSetRating?: (input: number) => void;
}

export default function Rating(props: RatingProps) {
  const {
    rating,
    editable = false,
    direction = "horizontal",
    externalSetRating,
  } = props;

  useEffect(() => {
    setRating(rating || 0);
  }, [rating]);

  const [localRating, setRating] = useState(rating || 0);
  const [hoverRating, setHoverRating] = useState<number | undefined>();

  return (
    <div
      className={`rating-container ${editable ? "editable" : ""} ${direction}`}
    >
      {[...new Array(5)].map((_, i) => (
        <RatingStar
          pos={i}
          rating={localRating}
          hover={hoverRating}
          setHover={setHoverRating}
          setClick={(v) => {
            editable && setRating(v);
            editable && externalSetRating && externalSetRating(v);
          }}
          key={i}
          editable={editable}
        />
      ))}
    </div>
  );
}

interface RatingStarProps {
  pos: number;
  hover?: number;
  rating: number;
  setHover: (input: number | undefined) => void;
  setClick: (input: number) => void;
  editable: boolean;
}

function RatingStar(props: RatingStarProps) {
  const { pos, rating, hover, setHover, setClick, editable } = props;

  let star = <Star size={20} />;

  if (typeof hover === "undefined" || !editable) {
    if (pos < Math.floor(rating) || pos === rating - 1) {
      star = <StarFill size={20} />;
    } else if (rating % 1 !== 0 && pos < rating) {
      star = <StarHalf size={20} />;
    }
  } else {
    if (pos < Math.floor(hover) || pos === hover - 1) {
      star = <StarFill size={20} />;
    } else if (hover % 1 !== 0 && pos < hover) {
      star = <StarHalf size={20} />;
    }
  }

  return (
    <div className="star-container">
      <div className="star">{star}</div>
      <div
        className="left"
        onClick={() => setClick(pos + 0.5)}
        onMouseEnter={() => setHover(pos + 0.5)}
        onMouseLeave={() => setHover(undefined)}
      />
      <div
        className="right"
        onClick={() => setClick(pos + 1)}
        onMouseEnter={() => setHover(pos + 1)}
        onMouseLeave={() => setHover(undefined)}
      />
    </div>
  );
}
