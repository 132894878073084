import { Badge, OverlayTrigger, Row, Stack, Tooltip } from "react-bootstrap";
import { Book as BookIcon, BookHalf, Pencil } from "react-bootstrap-icons";

import { Book, Category } from "../../app/types";
import { useUpdateBookMutation } from "../../services/api";
import Rating from "../shared/rating";

interface BookRowProps {
  book: Book;
  categories: Category[];
  setBook: (input: Book) => void;
  showModal: (input: boolean) => void;
}

export default function BookRow(props: BookRowProps) {
  const { book, categories, setBook, showModal } = props;
  const [updateBook] = useUpdateBookMutation();

  const categoryTags = book.category_array.map((bc) => {
    const badge = bc.hard_mode ? (
      <Badge bg="secondary" className="badge">
        H
      </Badge>
    ) : (
      ""
    );
    return (
      <div className="tag border" key={bc.category_id}>
        <span className="p-1">
          {categories.find((cat) => cat.id === bc.category_id)?.name}
        </span>
        {badge}
      </div>
    );
  });

  const toggleRead = async () => {
    await updateBook({ ...book, read: !book.read });
  };

  return (
    <div key={book.id}>
      <div className={`book-row border ${book.read && "read"}`}>
        {book.image && (
          <div className="image-column">
            <img src={book.image} alt={`${book.title} cover`} />
          </div>
        )}
        <div className="p-1 info-column">
          <div className="title-row">
            <div
              className="title-container"
              style={{ display: "flex", flexDirection: "row", gap: 5 }}
            >
              <h5>{book.title}</h5>
              <Rating rating={book.rating} />
            </div>
            <div className="icon-container">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Mark Book as {book.read ? "Unread" : "Read"}
                  </Tooltip>
                }
              >
                {book.read ? (
                  <BookHalf
                    size={20}
                    className="clickable-icon"
                    onClick={toggleRead}
                  />
                ) : (
                  <BookIcon
                    size={20}
                    className="clickable-icon"
                    onClick={toggleRead}
                  />
                )}
              </OverlayTrigger>
              <Pencil
                size={20}
                onClick={() => {
                  setBook(book);
                  showModal(true);
                }}
                className="clickable-icon"
              />
            </div>
          </div>
          <Row className="author">
            <span>{book.author}</span>
          </Row>
          <Row className="tags">
            <Stack direction="horizontal" gap={2}>
              {categoryTags}
            </Stack>
          </Row>
        </div>
      </div>
    </div>
  );
}
