import React, { useRef, useState } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { useForm, Controller, Control } from "react-hook-form";
import { InfoCircleFill } from "react-bootstrap-icons";
import { Book, Category } from "../../app/types";
import {
  useCreateBookCategoryMutation,
  useCreateBookMutation,
  useDeleteBookCategoryMutation,
  useUpdateBookCategoryMutation,
  useUpdateBookMutation,
} from "../../services/api";
import { getYear } from "../../functions/date";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { addToast } from "../../app/reducers/toast";
import Rating from "../shared/rating";

interface BookFormProps {
  book?: Book;
  categories: Category[];
  close: () => void;
}

export default function BookForm(props: BookFormProps) {
  const { book, close, categories } = props;
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Record<string, any>>();

  const [rating, setRating] = useState(book?.rating);

  const [createBook] = useCreateBookMutation();
  const [updateBook] = useUpdateBookMutation();

  const [createBookCategory] = useCreateBookCategoryMutation();
  const [updateBookCategory] = useUpdateBookCategoryMutation();
  const [deleteBookCategory] = useDeleteBookCategoryMutation();

  const { userId: user_id = "" } = useParams();

  const dispatch = useAppDispatch();

  const onSubmit = async (data: Record<string, any>) => {
    const { title, author, image, read, categories, hard_mode } = data;
    const year = getYear();
    // Update book
    if (book) {
      await updateBook({
        id: book.id,
        title,
        author,
        image,
        read,
        user_id,
        rating,
        position: book.position,
        board_id: book.board_id,
      });
      categories.forEach(async (category: boolean | undefined, i: number) => {
        if (!category) return;
        const cat = book.category_array.find((cat) => cat.category_id === i);

        if (cat && cat.hard_mode !== hard_mode[i]) {
          await updateBookCategory({ ...cat, hard_mode: hard_mode[i] });
        } else if (!cat) {
          await createBookCategory({
            user_id,
            book_id: book.id,
            category_id: i,
            hard_mode: hard_mode[i],
          });
        }
      });
      book.category_array.forEach(async (bc) => {
        if (!categories[bc.category_id]) {
          await deleteBookCategory({
            user_id,
            book_id: book.id,
            category_id: bc.category_id,
          });
        }
      });
      dispatch(
        addToast({
          header: "Success",
          body: "Successfully updated book",
          variant: "success",
        })
      );
      close();
      return;
    }

    // Create book
    const fBook = await createBook({
      title,
      author,
      image,
      read,
      year,
      user_id,
      rating,
    })
      .unwrap()
      .then((book) => book)
      .catch(() => {
        dispatch(
          addToast({
            header: "Error Creating Book",
            body: "Unable to create book.",
            variant: "danger",
          })
        );
        return null;
      });
    if (fBook === null) return;

    await categories.forEach(async (cat: boolean, i: number) => {
      if (cat) {
        const hm = hard_mode[i];
        await createBookCategory({
          book_id: fBook.id,
          user_id,
          category_id: i,
          hard_mode: hm,
        });
      }
    });
    dispatch(
      addToast({
        header: "Success",
        body: "Successfully created book.",
        variant: "success",
      })
    );
    close();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="book-form">
      <Row>
        <Col>
          <Form.Group controlId="formTitle">
            <Form.Label>Title</Form.Label>
            <Controller
              control={control}
              name="title"
              defaultValue={book?.title || ""}
              render={({ field: { onChange, value, ref } }) => (
                <Form.Control
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  isInvalid={!!errors.title}
                  placeholder="Book Title"
                  required
                />
              )}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formAuthor">
            <Form.Label>Author</Form.Label>
            <Controller
              control={control}
              name="author"
              defaultValue={book?.author || ""}
              render={({ field: { onChange, value, ref } }) => (
                <Form.Control
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  isInvalid={!!errors.title}
                  placeholder="Book Author"
                  required
                />
              )}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <Form.Group controlId="formTitle">
            <Form.Label>Image</Form.Label>
            <Controller
              control={control}
              name="image"
              defaultValue={book?.image || ""}
              render={({ field: { onChange, value, ref } }) => (
                <Form.Control
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  isInvalid={!!errors.image}
                  placeholder="URL to Image File"
                />
              )}
            />
          </Form.Group>
        </Col>
        <Col xs={2} className="rating-container">
          <Form.Group controlId="formRating">
            <Form.Label>Rating</Form.Label>
            <div>
              <Rating editable rating={rating} externalSetRating={setRating} />
            </div>
          </Form.Group>
        </Col>
        <Col className="read-container">
          <Controller
            control={control}
            name="read"
            defaultValue={book?.read || false}
            render={({ field: { onChange, value, ref } }) => (
              <Form.Check
                type="checkbox"
                name="read"
                onChange={onChange}
                value={value}
                label="Read"
                reverse
                defaultChecked={book?.read || false}
              />
            )}
          />
        </Col>
      </Row>
      <div className="category-grid">
        {[0, 1, 2, 3, 4].map((i) => {
          return (
            <Row className="g-0" key={i}>
              {categories.slice(i * 5, i * 5 + 5).map((category) => (
                <Col key={category.id}>
                  <CategoryCheckbox
                    category={category}
                    key={category.id}
                    control={control}
                    book={book}
                  />
                </Col>
              ))}
            </Row>
          );
        })}
      </div>
      <div className="buttons">
        <Button
          variant="secondary"
          onClick={() => {
            close();
          }}
        >
          Close
        </Button>
        <Button variant="primary" type="submit">
          Save
        </Button>
      </div>
    </Form>
  );
}

interface CategoryCheckboxProps {
  book?: Book;
  category: Category;
  control: Control<Record<string, any>, any>;
}

function CategoryCheckbox(props: CategoryCheckboxProps) {
  const { category, control, book } = props;
  const ref = useRef(null);
  const [checked, setChecked] = useState(
    !!book?.category_array.find((bc) => bc.category_id === category.id)
  );

  const onButtonClick = () => {
    // @ts-ignore
    ref.current?.click();
    setChecked(!checked);
  };

  return (
    <Container
      fluid
      className={`border p-1 h-100 ${checked && "text-bg-info"} prevent-select`}
      key={category.id}
    >
      <OverlayTrigger
        placement="top"
        trigger="click"
        overlay={
          <Popover>
            <Popover.Body
              dangerouslySetInnerHTML={{ __html: category.description }}
            ></Popover.Body>
          </Popover>
        }
        rootClose
      >
        <div className="info">
          <InfoCircleFill color="royalblue" size={20} />
        </div>
      </OverlayTrigger>
      <div onClick={onButtonClick} className="category-content">
        <span>{category.name}</span>
      </div>
      <Controller
        control={control}
        name={`categories.${category.id}`}
        defaultValue={checked}
        render={({ field: { onChange, value } }) => (
          <Form.Check
            type="checkbox"
            name={`categories.${category.id}`}
            onChange={onChange}
            value={value}
            hidden
            ref={ref}
            defaultChecked={checked}
          />
        )}
      />
      <div className="hard-checkbox">
        <Controller
          control={control}
          name={`hard_mode.${category.id}`}
          defaultValue={
            !!book?.category_array.find((bc) => bc.category_id === category.id)
              ?.hard_mode
          }
          render={({ field: { onChange, value } }) => (
            <Form.Check
              type="checkbox"
              name={`hard_mode.${category.id}`}
              onChange={onChange}
              value={value}
              label="Hard"
              reverse
              defaultChecked={
                !!book?.category_array.find(
                  (bc) => bc.category_id === category.id
                )?.hard_mode
              }
            />
          )}
        />
      </div>
    </Container>
  );
}
