import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import { addToast } from "../app/reducers/toast";
import {
  useCheckUserQuery,
  useListBooksQuery,
  useListCategoriesQuery,
} from "../services/api";
import Board from "./board/board";
import { Books } from "./books/books";

export default function Bingo() {
  const { userId: user_id = "" } = useParams();
  const dispatch = useAppDispatch();

  const { data: user, error: checkUserError } = useCheckUserQuery(user_id);
  const { data: books, error: listBooksError } = useListBooksQuery({
    user_id,
  });
  const { data: categories = [], error: listCategoriesError } =
    useListCategoriesQuery({});

  if (checkUserError) {
    dispatch(
      addToast({
        header: "Error Loading Page",
        body: "Invalid user",
        variant: "danger",
      })
    );
    return <div />;
  }

  if (
    !localStorage.getItem("user") &&
    typeof user !== "undefined" &&
    !checkUserError
  ) {
    localStorage.setItem("user", user.id);
  }

  if (listBooksError || listCategoriesError) {
    dispatch(
      addToast({
        header: "Error Loading Page",
        body: "Error retrieving books or categories",
        variant: "danger",
      })
    );
  }

  return (
    <Container fluid style={{ height: "100%" }}>
      <Row style={{ height: "100%" }}>
        <Col style={{ width: "50%", height: "100%" }}>
          <Books books={books} categories={categories} />
        </Col>
        <Col style={{ width: "50%" }}>
          <Board books={books} categories={categories} />
        </Col>
      </Row>
    </Container>
  );
}
