import { useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import { Book, Category } from "../../app/types";
import { getYear } from "../../functions/date";
import { useListBoardsQuery } from "../../services/api";
import BoardModal from "./board-modal";
import BoardCell from "./cell";
import SetBookModal from "./set-book-modal";

interface BoardProps {
  books?: Book[];
  categories?: Category[];
}

export default function Board(props: BoardProps) {
  const { books, categories } = props;

  const { userId: user_id = "" } = useParams();

  const year = getYear();
  const { data: boards = [] } = useListBoardsQuery({
    user_id,
    year,
  });

  const [activeBoard, setActiveBoard] = useState(0);
  const [showCreateBoardModal, setShowCreateBoardModal] = useState(false);

  const [showSetBookModal, setShowSetBookModal] = useState(false);
  const [settableBooks, setSettableBooks] = useState<Book[]>([]);
  const [category, setCategory] = useState<Category | undefined>();
  const [currentBook, setCurrentBook] = useState<Book | undefined>();

  if (typeof books === "undefined" || typeof categories === "undefined") {
    return <div />;
  }

  if (!categories.length) {
    return (
      <div className="board-grid">
        Please be patient while this year's categories are loaded
      </div>
    );
  }

  if (!boards.length) {
    return (
      <div className="board-grid">
        <div
          className="create-board"
          onClick={() => setShowCreateBoardModal(true)}
        >
          <div className="icon">
            <Plus size={90} />
          </div>
          <div className="text">Create New Bingo Board</div>
        </div>
        <BoardModal
          show={showCreateBoardModal}
          setShowModal={setShowCreateBoardModal}
        />
      </div>
    );
  }

  const arr = Array.from(new Array(25), (x, i) => i);
  return (
    <div className="board-container">
      <Tabs
        activeKey={activeBoard}
        onSelect={async (k) => {
          if (typeof k === "undefined" || k === null) {
            return;
          }
          if (k === "-1") {
            setShowCreateBoardModal(true);
            return;
          }
          setActiveBoard(parseInt(k));
        }}
      >
        {boards.map((board, i) => (
          <Tab key={board.id} eventKey={i} title={board.name}>
            <div className="board-grid">
              {[0, 1, 2, 3, 4].map((i) => (
                <Row className="g-0" key={i}>
                  {arr.slice(i * 5, i * 5 + 5).map((j) => (
                    <Col key={j}>
                      <BoardCell
                        books={books}
                        category={categories[j]}
                        key={j}
                        board={boards[activeBoard]}
                        setBooks={setSettableBooks}
                        openModal={() => setShowSetBookModal(true)}
                        setCategory={setCategory}
                        setCurrentBook={setCurrentBook}
                      />
                    </Col>
                  ))}
                </Row>
              ))}
            </div>
          </Tab>
        ))}
        <Tab
          key={-1}
          eventKey={-1}
          title={
            <span>
              <Plus size={24} /> Add Board
            </span>
          }
        />
      </Tabs>
      <BoardModal
        show={showCreateBoardModal}
        setShowModal={setShowCreateBoardModal}
      />
      <SetBookModal
        show={showSetBookModal}
        close={() => setShowSetBookModal(false)}
        books={settableBooks}
        category={category}
        board_id={boards[activeBoard].id}
        currentBook={currentBook}
      />
    </div>
  );
}
