import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  User,
  Book,
  Category,
  BookCategory,
  Stats,
  BookCategoryCount,
  Board,
  BookCategories,
} from "../app/types";
import { getYear } from "../functions/date";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    mode: "cors",
    prepareHeaders(headers, api) {
      return headers;
    },
  }),
  tagTypes: ["books", "boards"],
  endpoints: (builder) => ({
    checkUser: builder.query<User, string>({
      query: (userId) => userId,
    }),
    createUser: builder.mutation<User, null>({
      query: () => ({
        url: "user",
        method: "POST",
      }),
    }),
    listBooks: builder.query<Book[], { user_id: string; year?: number }>({
      query: ({ user_id, year }) => {
        if (!year) return `books?user_id=${user_id}&year=${getYear()}`;
        return `books?user_id=${user_id}&year=${year}`;
      },
      providesTags: ["books"],
    }),
    listCategories: builder.query<Category[], { year?: number }>({
      query: ({ year }) => {
        if (!year) return `categories?year=${getYear()}`;
        return `categories?year=${year}`;
      },
      providesTags: ["books"],
    }),
    listYears: builder.query<number[], null>({
      query: () => "years",
    }),
    createBook: builder.mutation<Book, Partial<Book>>({
      query: (input) => ({
        url: "books",
        method: "POST",
        body: input,
      }),
      invalidatesTags: ["books"],
    }),
    updateBook: builder.mutation<Book, Partial<Book>>({
      query: (input: Partial<Book>) => {
        const { id, ...body } = input;
        return {
          url: `books/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["books"],
    }),
    clearRead: builder.mutation<null, Partial<Book>>({
      query: (input: Partial<Book>) => ({
        url: "books/clear_read",
        method: "POST",
        body: input,
      }),
      invalidatesTags: ["books"],
    }),
    createBookCategory: builder.mutation<Book, Partial<BookCategory>>({
      query: (input: BookCategory) => ({
        url: "book_categories",
        method: "POST",
        body: input,
      }),
      invalidatesTags: ["books"],
    }),
    updateBookCategory: builder.mutation<Book, BookCategory>({
      query: (input: BookCategory) => {
        return {
          url: "book_categories",
          method: "PUT",
          body: input,
        };
      },
      invalidatesTags: ["books"],
    }),
    deleteBookCategory: builder.mutation<Book, Partial<BookCategory>>({
      query: (input: Partial<BookCategory>) => ({
        url: `book_categories?user_id=${input.user_id}&book_id=${input.book_id}&category_id=${input.category_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["books"],
    }),
    getStats: builder.mutation<Stats, string>({
      query: (user_id) => {
        const query = user_id ? `&user_id=${user_id}` : "";
        return { url: `stats?year=${getYear()}${query}` };
      },
    }),
    getCategoryStats: builder.mutation<
      BookCategoryCount[],
      { category_id: number; hard_mode: boolean }
    >({
      query: (input) => ({
        url: `stats/category?category_id=${input.category_id}&hard_mode=${
          input.hard_mode
        }&year=${getYear()}`,
      }),
    }),
    getBookCategories: builder.mutation<BookCategories[], string>({
      query: (input) => ({
        url: `stats/book?search=${input}&year=${getYear()}`,
      }),
    }),
    createBoard: builder.mutation<Board, Partial<Board>>({
      query: (input) => ({
        url: "boards",
        method: "POST",
        body: input,
      }),
      invalidatesTags: ["boards"],
    }),
    listBoards: builder.query<Board[], Partial<Board>>({
      query: (input) => ({
        url: `boards?user_id=${input.user_id}&year=${input.year}`,
      }),
      providesTags: ["books", "boards"],
    }),
  }),
});

export const {
  useCreateUserMutation,
  useCheckUserQuery,
  useListBooksQuery,
  useListCategoriesQuery,
  useListYearsQuery,
  useCreateBookCategoryMutation,
  useCreateBookMutation,
  useUpdateBookMutation,
  useDeleteBookCategoryMutation,
  useUpdateBookCategoryMutation,
  useClearReadMutation,
  useGetStatsMutation,
  useGetCategoryStatsMutation,
  useGetBookCategoriesMutation,
  useCreateBoardMutation,
  useListBoardsQuery,
} = api;
