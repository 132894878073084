import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ToastPayload {
  header: string;
  body: string;
  variant: "primary" | "success" | "danger" | "warning" | "info";
}

export const toastsSlice = createSlice({
  name: "toasts",
  initialState: Array<ToastPayload>,
  reducers: {
    addToast: (state, action: PayloadAction<ToastPayload>) => {
      if (
        !state.find(
          (t) =>
            t.body === action.payload.body &&
            t.header === action.payload.header &&
            t.variant === action.payload.variant
        )
      ) {
        state.push(action.payload);
      }
    },
    removeToast: (state, action: PayloadAction<number>) => {
      return state.filter((toast, i) => i !== action.payload);
    },
  },
});

export const { addToast, removeToast } = toastsSlice.actions;

export default toastsSlice.reducer;
