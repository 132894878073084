import { Toast, ToastContainer } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { removeToast, ToastPayload } from "../app/reducers/toast";

export default function Toasts() {
  const toasts = useAppSelector((state) => state.toasts);
  const dispatch = useAppDispatch();

  if (!toasts) {
    return <div />;
  }

  const toast = (t: ToastPayload, key: number): JSX.Element => (
    <Toast
      show={true}
      onClose={() => dispatch(removeToast(key))}
      key={key}
      autohide
      delay={5000}
      bg={t.variant}
    >
      <Toast.Header>
        <strong className="me-auto">{t.header}</strong>
      </Toast.Header>
      <Toast.Body>{t.body}</Toast.Body>
    </Toast>
  );

  const toRender = toasts.map((t, i) => toast(t, i));

  return (
    <ToastContainer
      containerPosition="absolute"
      position="bottom-center"
      className="toast-container"
    >
      {toRender}
    </ToastContainer>
  );
}
