import { ReactElement, useState } from "react";
import {
  Container,
  Navbar,
  Nav,
  Modal,
  Row,
  Button,
  NavDropdown,
  Form,
} from "react-bootstrap";
import { GraphUp } from "react-bootstrap-icons";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setTheme } from "../app/reducers/settings";
import Toast from "../components/toasts";
import { getYear } from "../functions/date";
import { api, useGetStatsMutation, useListYearsQuery } from "../services/api";
import { Stats } from "../app/types";

export default function Wrapper({ children }: { children: ReactElement }) {
  const user_id = localStorage.getItem("user") || "";
  const theme: string = useAppSelector((state) => state.settings.theme);

  const dispatch = useAppDispatch();

  let year = getYear();
  const [usedYear, setUsedYear] = useState(year);

  const { data: years = [] } = useListYearsQuery(null);
  const [stats, setStats] = useState<Stats | undefined>();
  const [getStats] = useGetStatsMutation();

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = async () => {
    const stat_resp = await getStats(user_id).unwrap();
    setStats(stat_resp);
    setShowModal(true);
  };

  return (
    <div className={`wrapper ${theme}`}>
      <Navbar bg="dark" variant="dark" className="shadow-sm">
        <Container fluid>
          <Navbar.Brand style={{ display: "flex", alignItems: "center" }}>
            /r/Fantasy Bingo
          </Navbar.Brand>
          <Navbar.Collapse>
            <Nav>
              <Nav.Link href={`/${user_id}`}>Home</Nav.Link>
              <Nav.Link href="/books">Find Books</Nav.Link>
              <NavDropdown title={`${usedYear} Board`}>
                {years?.map((year) => (
                  <NavDropdown.Item
                    key={year}
                    onClick={() => {
                      localStorage.setItem("year", year.toString());
                      setUsedYear(year);
                      dispatch(api.util.invalidateTags(["books"]));
                    }}
                  >
                    {year}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end navbar-right">
            <Navbar.Text>
              <Form.Check
                type="switch"
                label="Dark Mode"
                defaultChecked={theme === "dark"}
                onChange={(e) => {
                  // @ts-ignore
                  e.target.checked
                    ? dispatch(setTheme("dark"))
                    : dispatch(setTheme("light"));
                }}
                reverse
              />
            </Navbar.Text>
            <Navbar.Text>
              <GraphUp
                size={24}
                className="clickable-icon"
                onClick={handleOpenModal}
              />
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal
        show={showModal}
        size="lg"
        className="stats-modal"
        contentClassName={`shadow ${theme}`}
      >
        <Modal.Header>
          <Modal.Title>Statistics</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <StatBlock stat="Users" count={stats?.users || 0} />
          </Row>
          <Row>
            <StatBlock
              stat="Your Entered Books"
              count={stats?.user_total_books || 0}
            />
            <StatBlock
              stat="Your Read Books"
              count={stats?.user_read_books || 0}
            />
          </Row>
          <Row>
            <StatBlock
              stat="Global Entered Books"
              count={stats?.total_books || 0}
            />
            <StatBlock
              stat="Global Read Books"
              count={stats?.read_books || 0}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Container fluid className="p-2" style={{ height: "100%" }}>
        {children}
        <Toast />
      </Container>
    </div>
  );
}

function StatBlock({ stat, count }: { stat: string; count: number }) {
  return (
    <Container className="stats-container">
      <div className="count">{count}</div>
      <div className="stat">{stat}</div>
    </Container>
  );
}
