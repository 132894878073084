import { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Stack,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { useAppSelector } from "../../app/hooks";
import { BookCategories, BookCategoryCount, Category } from "../../app/types";
import {
  useGetCategoryStatsMutation,
  useGetBookCategoriesMutation,
  useListCategoriesQuery,
} from "../../services/api";
import { useDebounce } from "../shared/hooks";
import Rating from "../shared/rating";

export default function FindBooks() {
  const theme = useAppSelector((state) => state.settings.theme);

  const { data: categories = [] } = useListCategoriesQuery({});
  const [getCategoryStats] = useGetCategoryStatsMutation();
  const [getBookCategories] = useGetBookCategoriesMutation();

  const [activeFilterId, setActiveFilterId] = useState(-1);
  const [hardMode, setHardMode] = useState(false);
  const [bcStats, setBCStats] = useState<BookCategoryCount[]>([]);

  const [showPopover, setShowPopover] = useState(false);

  const [bookSearch, setBookSearch] = useState("");
  const [bookCategories, setBookCategories] = useState<BookCategories[]>([]);
  const debouncedSearch = useDebounce(bookSearch, 200);

  useEffect(() => {
    const fetchData = async () => {
      const resp = await getBookCategories(bookSearch).unwrap();
      setBookCategories(resp);
    };
    if (bookSearch) {
      fetchData().catch(console.error);
    }
  }, [debouncedSearch]);

  const setFilter = async (category_id: number, hard_mode: boolean) => {
    const resp = await getCategoryStats({
      category_id,
      hard_mode,
    }).unwrap();
    setBCStats(resp);
    setActiveFilterId(category_id);
    setHardMode(hard_mode);
  };

  const arr = Array.from(new Array(25), (x, i) => i);
  const popover = (
    <Popover style={{ maxWidth: "50%" }} className="find-book-popover">
      <Popover.Body>
        <div className="board-grid">
          {[0, 1, 2, 3, 4].map((i) => (
            <Row className="g-0" key={i}>
              {arr.slice(i * 5, i * 5 + 5).map((j) => (
                <Col key={j}>
                  <Container fluid className={`border h-100 prevent-select}`}>
                    <div
                      className="cell-content"
                      onClick={() => {
                        setFilter(categories[j]?.id, hardMode);
                        setShowPopover(false);
                      }}
                    >
                      <div className="category-content">
                        {categories[j]?.name}
                      </div>
                    </div>
                  </Container>
                </Col>
              ))}
            </Row>
          ))}
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <Container style={{ height: "100%" }} className="find-books">
      <Tabs defaultActiveKey="categories">
        <Tab eventKey="categories" title="Find By Category">
          <div className="filters">
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={popover}
              rootClose
              show={showPopover}
              onToggle={() => setShowPopover(!showPopover)}
            >
              <Button variant="primary" type="button">
                {categories.find((cat) => cat.id === activeFilterId)?.name ||
                  "Select Category"}
              </Button>
            </OverlayTrigger>
            <Form.Check
              defaultChecked={hardMode}
              onChange={(e) => setFilter(activeFilterId, e.target.checked)}
              label="Hard mode only"
              type="checkbox"
              style={{ marginLeft: "0.75rem" }}
            ></Form.Check>
          </div>
          <div className="find-book-table prevent-select">
            <Table striped={theme === "light"} bordered>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Author</th>
                  <th>Count</th>
                  <th>Average Rating</th>
                </tr>
              </thead>
              <tbody>
                {bcStats.map((row, i) => (
                  <tr key={i}>
                    <td>{row.title}</td>
                    <td>{row.author}</td>
                    <td>{row.count}</td>
                    <td>
                      {row.rating ? parseFloat(row.rating.toString()) : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {!bcStats.length && <div>Please select a category</div>}
          </div>
        </Tab>
        <Tab eventKey="books" title="Find By Book">
          <div
            className="find-by-book-container"
            style={{ overflow: "auto", height: "calc(100vh - 110px)" }}
          >
            <Form.Control
              onChange={(e) => setBookSearch(e.target.value)}
              placeholder="Book Title or Author"
              style={{ marginBottom: 10 }}
            />
            <div className="book-categories-table">
              {bookCategories.map((bc, i) => (
                <BookCategoryRow
                  categories={categories}
                  book_categories={bc}
                  key={i}
                />
              ))}
            </div>
          </div>
        </Tab>
      </Tabs>
    </Container>
  );
}

interface BookCategoryRowProps {
  categories?: Category[];
  book_categories: BookCategories;
}

function BookCategoryRow(props: BookCategoryRowProps) {
  const { categories = [], book_categories } = props;

  const categoryTags = book_categories.categories.map((bc) => {
    const badge = (
      <Badge bg="secondary" className="badge">
        {parseFloat((bc[1] / book_categories.total).toPrecision(2).toString()) *
          100}
        %
      </Badge>
    );
    return (
      <div className="tag border" key={bc[0]}>
        <span className="p-1">
          {categories.find((cat) => cat.id === bc[0])?.name}
        </span>
        {badge}
      </div>
    );
  });

  return (
    <div>
      <div className={`book-row border`}>
        <div className="p-1 info-column">
          <div className="title-row">
            <div
              className="title-container"
              style={{ display: "flex", flexDirection: "row", gap: 5 }}
            >
              <h5>{book_categories.title}</h5>
              <Rating rating={book_categories.rating} />
            </div>
            <div className="icon-container">
              Logged {book_categories.total} Time
              {book_categories.total !== 1 ? "s" : ""}
            </div>
          </div>
          <Row className="author">
            <span>{book_categories.author}</span>
          </Row>
          <Row className="tags">
            <Stack direction="horizontal" gap={2}>
              {categoryTags}
            </Stack>
          </Row>
        </div>
      </div>
    </div>
  );
}
