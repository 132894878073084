import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Theme = "light" | "dark";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: { theme: localStorage.getItem("theme") || "light" },
  reducers: {
    setTheme: (state, action: PayloadAction<Theme>) => {
      state.theme = action.payload;
      localStorage.setItem("theme", action.payload);
    },
  },
});

export const { setTheme } = settingsSlice.actions;

export default settingsSlice.reducer;
